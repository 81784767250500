:root {
  --max-width-component-top-banner: 1080px;

  --max-width-component-top-nav-bar: 1080px;

  color: rgb(var(--color-santa-o));

  background-color: rgb(var(--color-santa-a));

  color-scheme: light;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
  }
}

:root[data-color-scheme="light"] {
  color-scheme: light;
}

:root[data-color-scheme="dark"] {
  color-scheme: dark;
}

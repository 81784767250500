@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;700&display=block');

/* TODO: Improve accessibility */
* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
html,
body {
  overflow: hidden;
}

a {
  text-decoration: none;
}


:root {

  --breakpoint-small: 320px;
  --breakpoint-medium: 600px;
  --breakpoint-large: 960px;
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-size-s-h1: 3.75rem;
  --font-size-s-h2: 3.125rem;
  --font-size-s-h3: 2.5rem;
  --font-size-s-h4: 1.875rem;
  --font-size-s-h5: 1.375rem;
  --font-size-s-body1: 1.125rem;
  --font-size-s-body2: 1rem;
  --font-size-s-body3: 0.875rem;
  --font-size-s-caption1: 0.75rem;
  --font-size-s-caption2: 0.625rem;
  --font-size-s-caption3: 0.5rem;
  --font-size-s-point: 0.75rem;
  --font-size-h1: var(--font-size-s-h1);
  --font-size-h2: var(--font-size-s-h2);
  --font-size-h3: var(--font-size-s-h3);
  --font-size-h4: var(--font-size-s-h4);
  --font-size-h5: var(--font-size-s-h5);
  --font-size-body1: var(--font-size-s-body1);
  --font-size-body2: var(--font-size-s-body2);
  --font-size-body3: var(--font-size-s-body3);
  --font-size-caption1: var(--font-size-s-caption1);
  --font-size-caption2: var(--font-size-s-caption2);
  --font-size-caption3: var(--font-size-s-caption3);
  --font-size-point: var(--font-size-s-point);
  --letter-spacing-h1: -0.075rem;
  --letter-spacing-h2: -0.0562rem;
  --letter-spacing-h3: -0.04rem;
  --letter-spacing-h4: -0.03rem;
  --letter-spacing-h5: -0.0192rem;
  --letter-spacing-body1: -0.0135rem;
  --letter-spacing-body2: -0.012rem;
  --letter-spacing-body3: -0.0105rem;
  --letter-spacing-caption1: -0.009rem;
  --letter-spacing-caption2: -0.0075rem;
  --letter-spacing-caption3: -0.006rem;
  --letter-spacing-point: 0.165rem;
  --line-height-s-h1: 5.75rem;
  --line-height-s-h2: 5rem;
  --line-height-s-h3: 4.125rem;
  --line-height-s-h4: 3.25rem;
  --line-height-s-h5: 2.4375rem;
  --line-height-s-body1: 2.125rem;
  --line-height-s-body2: 2rem;
  --line-height-s-body3: 1.75rem;
  --line-height-s-caption1: 1.25rem;
  --line-height-s-caption2: 1.125rem;
  --line-height-s-caption3: 0.875rem;
  --line-height-s-point: 1.25rem;
  --line-height-h1: var(--line-height-s-h1);
  --line-height-h2: var(--line-height-s-h2);
  --line-height-h3: var(--line-height-s-h3);
  --line-height-h4: var(--line-height-s-h4);
  --line-height-h5: var(--line-height-s-h5);
  --line-height-body1: var(--line-height-s-body1);
  --line-height-body2: var(--line-height-s-body2);
  --line-height-body3: var(--line-height-s-body3);
  --line-height-caption1: var(--line-height-s-caption1);
  --line-height-caption2: var(--line-height-s-caption2);
  --line-height-caption3: var(--line-height-s-caption3);
  --line-height-point: var(--line-height-s-point);
  --color-santa-toeic-danger-5: 142, 13, 11;
  --color-santa-toeic-danger-4: 190, 17, 14;
  --color-santa-toeic-danger-3: 237, 22, 18;
  --color-santa-toeic-danger-2: 244, 115, 113;
  --color-santa-toeic-danger-1: 248, 162, 160;
  --color-fix-santa-danger-5: 142, 13, 11;
  --color-fix-santa-danger-4: 190, 17, 14;
  --color-fix-santa-danger-3: 237, 22, 18;
  --color-fix-santa-danger-2: 244, 115, 113;
  --color-fix-santa-danger-1: 248, 162, 160;
  --color-santa-toeic-white-3: 244, 244, 244;
  --color-fix-santa-white-3: 244, 244, 244;
  --color-santa-toeic-white-2: 248, 248, 248;
  --color-fix-santa-white-2: 248, 248, 248;
  --color-santa-toeic-white-1: 255, 255, 255;
  --color-fix-santa-white-1: 255, 255, 255;
  --color-santa-toeic-gray-9: 46, 46, 46;
  --color-fix-santa-gray-9: 46, 46, 46;
  --color-santa-toeic-gray-8: 63, 63, 63;
  --color-fix-santa-gray-8: 63, 63, 63;
  --color-santa-toeic-gray-7: 80, 80, 80;
  --color-fix-santa-gray-7: 80, 80, 80;
  --color-santa-toeic-gray-6: 106, 106, 106;
  --color-fix-santa-gray-6: 106, 106, 106;
  --color-santa-toeic-gray-5: 134, 134, 134;
  --color-fix-santa-gray-5: 134, 134, 134;
  --color-santa-toeic-gray-4: 162, 161, 161;
  --color-fix-santa-gray-4: 162, 161, 161;
  --color-santa-toeic-gray-3: 191, 191, 191;
  --color-fix-santa-gray-3: 191, 191, 191;
  --color-santa-toeic-gray-2: 221, 221, 221;
  --color-fix-santa-gray-2: 221, 221, 221;
  --color-santa-toeic-gray-1: 235, 235, 235;
  --color-fix-santa-gray-1: 235, 235, 235;
  --color-santa-toeic-black-1: 4, 4, 4;
  --color-fix-santa-black-1: 4, 4, 4;
  --color-santa-toeic-black-2: 19, 19, 19;
  --color-fix-santa-black-2: 19, 19, 19;
  --color-santa-toeic-black-3: 32, 32, 32;
  --color-fix-santa-black-3: 32, 32, 32;
  --color-santa-toeic-brand-5: 3, 50, 209;
  --color-santa-toeic-brand-4: 20, 101, 255;
  --color-santa-toeic-brand-3: 50, 132, 255;
  --color-santa-toeic-brand-2: 130, 184, 255;
  --color-santa-toeic-brand-1: 165, 204, 255;
  --color-fix-space-blue-gray-8: 3, 50, 209;
  --color-fix-space-blue-gray-7: 20, 72, 244;
  --color-fix-space-blue-gray-6: 20, 101, 255;
  --color-fix-space-blue-gray-5: 50, 132, 255;
  --color-fix-space-blue-gray-4: 88, 159, 255;
  --color-fix-space-blue-gray-3: 130, 184, 255;
  --color-fix-space-blue-gray-2: 165, 204, 255;
  --color-fix-space-blue-gray-1: 199, 222, 255;
  --color-fix-space-blue-gray-9: 18, 38, 170;
  --color-fix-space-blue-black-3: 15, 30, 126;
  --color-fix-space-blue-black-2: 5, 26, 96;
  --color-fix-space-blue-black-1: 0, 11, 83;
  --color-fix-space-blue-white-3: 223, 237, 255;
  --color-fix-space-blue-white-2: 243, 249, 255;
  --color-fix-space-blue-white-1: 255, 255, 255;
  --color-space-blue-gray-8: 3, 50, 209;
  --color-horizon-blue-gray-8: 27, 55, 173;
  --color-space-blue-gray-7: 20, 72, 244;
  --color-horizon-blue-gray-7: 48, 78, 206;
  --color-space-blue-gray-6: 20, 101, 255;
  --color-horizon-blue-gray-6: 72, 101, 227;
  --color-space-blue-gray-5: 50, 132, 255;
  --color-horizon-blue-gray-5: 97, 124, 244;
  --color-space-blue-gray-4: 88, 159, 255;
  --color-horizon-blue-gray-4: 129, 152, 249;
  --color-space-blue-gray-3: 130, 184, 255;
  --color-horizon-blue-gray-3: 160, 178, 255;
  --color-space-blue-gray-2: 165, 204, 255;
  --color-horizon-blue-gray-2: 184, 197, 255;
  --color-space-blue-gray-1: 199, 222, 255;
  --color-horizon-blue-gray-1: 207, 216, 255;
  --color-space-blue-gray-9: 18, 38, 170;
  --color-horizon-blue-gray-9: 11, 40, 144;
  --color-space-blue-black-3: 15, 30, 126;
  --color-horizon-blue-black-3: 0, 26, 112;
  --color-space-blue-black-2: 5, 26, 96;
  --color-space-blue-black-1: 0, 11, 83;
  --color-horizon-blue-black-2: 1, 21, 88;
  --color-horizon-blue-black-1: 11, 22, 69;
  --color-space-blue-white-3: 223, 237, 255;
  --color-horizon-blue-white-3: 233, 237, 255;
  --color-space-blue-white-2: 243, 249, 255;
  --color-horizon-blue-white-2: 246, 248, 255;
  --color-space-blue-white-1: 255, 255, 255;
  --color-horizon-blue-white-1: 255, 255, 255;
  --color-deepocean-blue-gray-9: 2, 77, 146;
  --color-deepocean-blue-gray-8: 5, 98, 184;
  --color-deepocean-blue-gray-7: 0, 124, 215;
  --color-deepocean-blue-gray-6: 10, 156, 238;
  --color-deepocean-blue-gray-5: 47, 177, 251;
  --color-deepocean-blue-gray-4: 87, 194, 255;
  --color-deepocean-blue-gray-3: 130, 211, 255;
  --color-deepocean-blue-gray-2: 162, 222, 255;
  --color-deepocean-blue-gray-1: 192, 232, 255;
  --color-deepocean-blue-black-3: 0, 59, 113;
  --color-deepocean-blue-black-2: 0, 42, 81;
  --color-deepocean-blue-black-1: 1, 30, 65;
  --color-deepocean-blue-white-3: 219, 244, 255;
  --color-deepocean-blue-white-2: 238, 250, 255;
  --color-deepocean-blue-white-1: 255, 255, 255;
  --color-riiid-yellow-3: 252, 223, 88;
  --color-riiid-yellow-2: 255, 238, 141;
  --color-riiid-yellow-1: 255, 248, 203;
  --color-riiid-green-3: 8, 189, 186;
  --color-riiid-green-2: 136, 220, 219;
  --color-riiid-green-1: 205, 245, 245;
  --color-riiid-purple-3: 109, 79, 180;
  --color-riiid-purple-2: 185, 160, 240;
  --color-riiid-purple-1: 227, 215, 255;
  --color-riiid-red-3: 249, 115, 113;
  --color-riiid-red-2: 253, 152, 151;
  --color-riiid-red-1: 255, 209, 214;
  --color-riiid-violet-3: 180, 79, 148;
  --color-riiid-violet-2: 220, 159, 211;
  --color-riiid-violet-1: 250, 220, 244;
  --color-santa-card-f-light: 184, 197, 255;
  --color-santa-card-e-light: 191, 191, 191;
  --color-santa-card-d-light: 221, 221, 221;
  --color-santa-card-c-light: 235, 235, 235;
  --color-santa-card-b-light: 244, 244, 244;
  --color-santa-card-a-light: 255, 255, 255;
  --color-riiid-secondary-ya-light: 255, 248, 203;
  --color-riiid-secondary-yb-light: 255, 238, 141;
  --color-riiid-secondary-yc-light: 252, 223, 88;
  --color-riiid-secondary-ga-light: 205, 245, 245;
  --color-riiid-secondary-gb-light: 136, 220, 219;
  --color-riiid-secondary-gc-light: 8, 189, 186;
  --color-riiid-secondary-pa-light: 227, 215, 255;
  --color-riiid-secondary-pb-light: 185, 160, 240;
  --color-riiid-secondary-pc-light: 109, 79, 180;
  --color-riiid-secondary-ra-light: 255, 209, 214;
  --color-riiid-secondary-rb-light: 253, 152, 151;
  --color-riiid-secondary-rc-light: 249, 115, 113;
  --color-riiid-secondary-va-light: 250, 220, 244;
  --color-riiid-secondary-vb-light: 220, 159, 211;
  --color-riiid-secondary-vc-light: 180, 79, 148;
  --color-santa-a-light: 255, 255, 255;
  --color-santa-b-light: 248, 248, 248;
  --color-santa-c-light: 244, 244, 244;
  --color-santa-d-light: 235, 235, 235;
  --color-santa-e-light: 221, 221, 221;
  --color-santa-f-light: 191, 191, 191;
  --color-santa-g-light: 162, 161, 161;
  --color-santa-h-light: 134, 134, 134;
  --color-santa-i-light: 106, 106, 106;
  --color-santa-j-light: 80, 80, 80;
  --color-santa-k-light: 63, 63, 63;
  --color-santa-l-light: 46, 46, 46;
  --color-santa-m-light: 32, 32, 32;
  --color-santa-n-light: 19, 19, 19;
  --color-santa-o-light: 4, 4, 4;
  --color-santa-ba-light: 165, 204, 255;
  --color-santa-bb-light: 130, 184, 255;
  --color-santa-bc-light: 50, 132, 255;
  --color-santa-bd-light: 20, 101, 255;
  --color-santa-be-light: 3, 50, 209;
  --color-santa-da-light: 248, 162, 160;
  --color-santa-db-light: 244, 115, 113;
  --color-santa-dc-light: 237, 22, 18;
  --color-santa-dd-light: 190, 17, 14;
  --color-santa-de-light: 142, 13, 11;
  --color-riiid-doba-light: 255, 255, 255;
  --color-riiid-dobb-light: 238, 250, 255;
  --color-riiid-dobc-light: 219, 244, 255;
  --color-riiid-dobd-light: 192, 232, 255;
  --color-riiid-dobe-light: 162, 222, 255;
  --color-riiid-dobf-light: 130, 211, 255;
  --color-riiid-dobg-light: 87, 194, 255;
  --color-riiid-dobh-light: 47, 177, 251;
  --color-riiid-dobi-light: 10, 156, 238;
  --color-riiid-dobj-light: 0, 124, 215;
  --color-riiid-dobk-light: 5, 98, 184;
  --color-riiid-dobl-light: 2, 77, 146;
  --color-riiid-dobm-light: 0, 59, 113;
  --color-riiid-dobn-light: 0, 42, 81;
  --color-riiid-dobo-light: 1, 30, 65;
  --color-riiid-spba-light: 255, 255, 255;
  --color-riiid-spbb-light: 243, 249, 255;
  --color-riiid-spbc-light: 223, 237, 255;
  --color-riiid-spbd-light: 199, 222, 255;
  --color-riiid-spbe-light: 165, 204, 255;
  --color-riiid-spbf-light: 130, 184, 255;
  --color-riiid-spbg-light: 88, 159, 255;
  --color-riiid-spbh-light: 50, 132, 255;
  --color-riiid-spbi-light: 20, 101, 255;
  --color-riiid-spbj-light: 20, 72, 244;
  --color-riiid-spbk-light: 3, 50, 209;
  --color-riiid-spbl-light: 18, 38, 170;
  --color-riiid-spbm-light: 15, 30, 126;
  --color-riiid-spbn-light: 5, 26, 96;
  --color-riiid-spbo-light: 0, 11, 83;
  --color-riiid-hzba-light: 255, 255, 255;
  --color-riiid-hzbb-light: 246, 248, 255;
  --color-riiid-hzbc-light: 233, 237, 255;
  --color-riiid-hzbd-light: 207, 216, 255;
  --color-riiid-hzbe-light: 184, 197, 255;
  --color-riiid-hzbf-light: 160, 178, 255;
  --color-riiid-hzbg-light: 129, 152, 249;
  --color-riiid-hzbh-light: 97, 124, 244;
  --color-riiid-hzbi-light: 72, 101, 227;
  --color-riiid-hzbj-light: 48, 78, 206;
  --color-riiid-hzbk-light: 27, 55, 173;
  --color-riiid-hzbl-light: 11, 40, 144;
  --color-riiid-hzbm-light: 0, 26, 112;
  --color-riiid-hzbn-light: 1, 21, 88;
  --color-riiid-hzbo-light: 11, 22, 69;
  --color-santa-card-f-dark: 27, 55, 173;
  --color-santa-card-e-dark: 32, 32, 32;
  --color-santa-card-d-dark: 46, 46, 46;
  --color-santa-card-c-dark: 63, 63, 63;
  --color-santa-card-b-dark: 63, 63, 63;
  --color-santa-card-a-dark: 80, 80, 80;
  --color-riiid-secondary-ya-dark: 252, 223, 88;
  --color-riiid-secondary-yb-dark: 255, 238, 141;
  --color-riiid-secondary-yc-dark: 255, 248, 203;
  --color-riiid-secondary-ga-dark: 8, 189, 186;
  --color-riiid-secondary-gb-dark: 136, 220, 219;
  --color-riiid-secondary-gc-dark: 205, 245, 245;
  --color-riiid-secondary-pa-dark: 109, 79, 180;
  --color-riiid-secondary-pb-dark: 185, 160, 240;
  --color-riiid-secondary-pc-dark: 227, 215, 255;
  --color-riiid-secondary-ra-dark: 249, 115, 113;
  --color-riiid-secondary-rb-dark: 253, 152, 151;
  --color-riiid-secondary-rc-dark: 255, 209, 214;
  --color-riiid-secondary-va-dark: 180, 79, 148;
  --color-riiid-secondary-vb-dark: 220, 159, 211;
  --color-riiid-secondary-vc-dark: 250, 220, 244;
  --color-santa-a-dark: 4, 4, 4;
  --color-santa-b-dark: 19, 19, 19;
  --color-santa-c-dark: 32, 32, 32;
  --color-santa-d-dark: 46, 46, 46;
  --color-santa-e-dark: 63, 63, 63;
  --color-santa-f-dark: 80, 80, 80;
  --color-santa-g-dark: 106, 106, 106;
  --color-santa-h-dark: 134, 134, 134;
  --color-santa-i-dark: 162, 161, 161;
  --color-santa-j-dark: 191, 191, 191;
  --color-santa-k-dark: 221, 221, 221;
  --color-santa-l-dark: 235, 235, 235;
  --color-santa-m-dark: 244, 244, 244;
  --color-santa-n-dark: 248, 248, 248;
  --color-santa-o-dark: 255, 255, 255;
  --color-santa-ba-dark: 3, 50, 209;
  --color-santa-bb-dark: 20, 101, 255;
  --color-santa-bc-dark: 50, 132, 255;
  --color-santa-bd-dark: 130, 184, 255;
  --color-santa-be-dark: 165, 204, 255;
  --color-santa-da-dark: 142, 13, 11;
  --color-santa-db-dark: 190, 17, 14;
  --color-santa-dc-dark: 237, 22, 18;
  --color-santa-dd-dark: 244, 115, 113;
  --color-santa-de-dark: 248, 162, 160;
  --color-riiid-doba-dark: 1, 30, 65;
  --color-riiid-dobb-dark: 0, 42, 81;
  --color-riiid-dobc-dark: 0, 59, 113;
  --color-riiid-dobd-dark: 2, 77, 146;
  --color-riiid-dobe-dark: 5, 98, 184;
  --color-riiid-dobf-dark: 0, 124, 215;
  --color-riiid-dobg-dark: 10, 156, 238;
  --color-riiid-dobh-dark: 47, 177, 251;
  --color-riiid-dobi-dark: 87, 194, 255;
  --color-riiid-dobj-dark: 130, 211, 255;
  --color-riiid-doak-dark: 162, 222, 255;
  --color-riiid-dobl-dark: 192, 232, 255;
  --color-riiid-dobm-dark: 219, 244, 255;
  --color-riiid-dobn-dark: 238, 250, 255;
  --color-riiid-dobo-dark: 255, 255, 255;
  --color-riiid-spba-dark: 0, 11, 83;
  --color-riiid-spbb-dark: 5, 26, 96;
  --color-riiid-spbc-dark: 15, 30, 126;
  --color-riiid-spbd-dark: 18, 38, 170;
  --color-riiid-spbe-dark: 3, 50, 209;
  --color-riiid-spbf-dark: 20, 72, 244;
  --color-riiid-spbg-dark: 20, 101, 255;
  --color-riiid-spbh-dark: 50, 132, 255;
  --color-riiid-spbi-dark: 88, 159, 255;
  --color-riiid-spbj-dark: 130, 184, 255;
  --color-riiid-spbk-dark: 165, 204, 255;
  --color-riiid-spbl-dark: 199, 222, 255;
  --color-riiid-spbm-dark: 223, 237, 255;
  --color-riiid-spbn-dark: 243, 249, 255;
  --color-riiid-spbo-dark: 255, 255, 255;
  --color-riiid-hzba-dark: 11, 22, 69;
  --color-riiid-hzbb-dark: 1, 21, 88;
  --color-riiid-hzbc-dark: 0, 26, 112;
  --color-riiid-hzbd-dark: 11, 40, 144;
  --color-riiid-hzbe-dark: 27, 55, 173;
  --color-riiid-hzbf-dark: 48, 78, 206;
  --color-riiid-hzbg-dark: 72, 101, 227;
  --color-riiid-hzbh-dark: 97, 124, 244;
  --color-riiid-hzbi-dark: 129, 152, 249;
  --color-riiid-hzbj-dark: 160, 178, 255;
  --color-riiid-hzbk-dark: 184, 197, 255;
  --color-riiid-hzbl-dark: 207, 216, 255;
  --color-riiid-hzbm-dark: 233, 237, 255;
  --color-riiid-hzbn-dark: 246, 248, 255;
  --color-riiid-hzbo-dark: 255, 255, 255;
  --color-santa-card-f: var(--color-santa-card-f-light);
  --color-santa-card-e: var(--color-santa-card-e-light);
  --color-santa-card-d: var(--color-santa-card-d-light);
  --color-santa-card-c: var(--color-santa-card-c-light);
  --color-santa-card-b: var(--color-santa-card-b-light);
  --color-santa-card-a: var(--color-santa-card-a-light);
  --color-riiid-secondary-ya: var(--color-riiid-secondary-ya-light);
  --color-riiid-secondary-yb: var(--color-riiid-secondary-yb-light);
  --color-riiid-secondary-yc: var(--color-riiid-secondary-yc-light);
  --color-riiid-secondary-ga: var(--color-riiid-secondary-ga-light);
  --color-riiid-secondary-gb: var(--color-riiid-secondary-gb-light);
  --color-riiid-secondary-gc: var(--color-riiid-secondary-gc-light);
  --color-riiid-secondary-pa: var(--color-riiid-secondary-pa-light);
  --color-riiid-secondary-pb: var(--color-riiid-secondary-pb-light);
  --color-riiid-secondary-pc: var(--color-riiid-secondary-pc-light);
  --color-riiid-secondary-ra: var(--color-riiid-secondary-ra-light);
  --color-riiid-secondary-rb: var(--color-riiid-secondary-rb-light);
  --color-riiid-secondary-rc: var(--color-riiid-secondary-rc-light);
  --color-riiid-secondary-va: var(--color-riiid-secondary-va-light);
  --color-riiid-secondary-vb: var(--color-riiid-secondary-vb-light);
  --color-riiid-secondary-vc: var(--color-riiid-secondary-vc-light);
  --color-santa-a: var(--color-santa-a-light);
  --color-santa-b: var(--color-santa-b-light);
  --color-santa-c: var(--color-santa-c-light);
  --color-santa-d: var(--color-santa-d-light);
  --color-santa-e: var(--color-santa-e-light);
  --color-santa-f: var(--color-santa-f-light);
  --color-santa-g: var(--color-santa-g-light);
  --color-santa-h: var(--color-santa-h-light);
  --color-santa-i: var(--color-santa-i-light);
  --color-santa-j: var(--color-santa-j-light);
  --color-santa-k: var(--color-santa-k-light);
  --color-santa-l: var(--color-santa-l-light);
  --color-santa-m: var(--color-santa-m-light);
  --color-santa-n: var(--color-santa-n-light);
  --color-santa-o: var(--color-santa-o-light);
  --color-santa-ba: var(--color-santa-ba-light);
  --color-santa-bb: var(--color-santa-bb-light);
  --color-santa-bc: var(--color-santa-bc-light);
  --color-santa-bd: var(--color-santa-bd-light);
  --color-santa-be: var(--color-santa-be-light);
  --color-santa-da: var(--color-santa-da-light);
  --color-santa-db: var(--color-santa-db-light);
  --color-santa-dc: var(--color-santa-dc-light);
  --color-santa-dd: var(--color-santa-dd-light);
  --color-santa-de: var(--color-santa-de-light);
  --color-riiid-doba: var(--color-riiid-doba-light);
  --color-riiid-dobb: var(--color-riiid-dobb-light);
  --color-riiid-dobc: var(--color-riiid-dobc-light);
  --color-riiid-dobd: var(--color-riiid-dobd-light);
  --color-riiid-dobe: var(--color-riiid-dobe-light);
  --color-riiid-dobf: var(--color-riiid-dobf-light);
  --color-riiid-dobg: var(--color-riiid-dobg-light);
  --color-riiid-dobh: var(--color-riiid-dobh-light);
  --color-riiid-dobi: var(--color-riiid-dobi-light);
  --color-riiid-dobj: var(--color-riiid-dobj-light);
  --color-riiid-dobk: var(--color-riiid-dobk-light);
  --color-riiid-dobl: var(--color-riiid-dobl-light);
  --color-riiid-dobm: var(--color-riiid-dobm-light);
  --color-riiid-dobn: var(--color-riiid-dobn-light);
  --color-riiid-dobo: var(--color-riiid-dobo-light);
  --color-riiid-spba: var(--color-riiid-spba-light);
  --color-riiid-spbb: var(--color-riiid-spbb-light);
  --color-riiid-spbc: var(--color-riiid-spbc-light);
  --color-riiid-spbd: var(--color-riiid-spbd-light);
  --color-riiid-spbe: var(--color-riiid-spbe-light);
  --color-riiid-spbf: var(--color-riiid-spbf-light);
  --color-riiid-spbg: var(--color-riiid-spbg-light);
  --color-riiid-spbh: var(--color-riiid-spbh-light);
  --color-riiid-spbi: var(--color-riiid-spbi-light);
  --color-riiid-spbj: var(--color-riiid-spbj-light);
  --color-riiid-spbk: var(--color-riiid-spbk-light);
  --color-riiid-spbl: var(--color-riiid-spbl-light);
  --color-riiid-spbm: var(--color-riiid-spbm-light);
  --color-riiid-spbn: var(--color-riiid-spbn-light);
  --color-riiid-spbo: var(--color-riiid-spbo-light);
  --color-riiid-hzba: var(--color-riiid-hzba-light);
  --color-riiid-hzbb: var(--color-riiid-hzbb-light);
  --color-riiid-hzbc: var(--color-riiid-hzbc-light);
  --color-riiid-hzbd: var(--color-riiid-hzbd-light);
  --color-riiid-hzbe: var(--color-riiid-hzbe-light);
  --color-riiid-hzbf: var(--color-riiid-hzbf-light);
  --color-riiid-hzbg: var(--color-riiid-hzbg-light);
  --color-riiid-hzbh: var(--color-riiid-hzbh-light);
  --color-riiid-hzbi: var(--color-riiid-hzbi-light);
  --color-riiid-hzbj: var(--color-riiid-hzbj-light);
  --color-riiid-hzbk: var(--color-riiid-hzbk-light);
  --color-riiid-hzbl: var(--color-riiid-hzbl-light);
  --color-riiid-hzbm: var(--color-riiid-hzbm-light);
  --color-riiid-hzbn: var(--color-riiid-hzbn-light);
  --color-riiid-hzbo: var(--color-riiid-hzbo-light);
  --shadow-a-1-down: 0px 3px 7px rgba(0, 0, 0, 0.14);
  --shadow-a-1-top: 0px -3px 7px rgba(0, 0, 0, 0.14);
  --shadow-a-1-left: -3px 0px 7px rgba(0, 0, 0, 0.14);
  --shadow-a-1-right: 3px 0px 7px rgba(0, 0, 0, 0.14);
  --shadow-a-2-down: 0px 6px 14px rgba(0, 0, 0, 0.12);
  --shadow-a-3-down: 0px 10px 30px rgba(0, 0, 0, 0.12);
  --shadow-a-2-top: 0px -6px 14px rgba(0, 0, 0, 0.12);
  --shadow-a-2-left: -6px 0px 14px rgba(0, 0, 0, 0.12);
  --shadow-a-2-right: 6px 0px 14px rgba(0, 0, 0, 0.12);
  --shadow-a-3-top: 0px -10px 30px rgba(0, 0, 0, 0.12);
  --shadow-a-3-left: -10px 0px 30px rgba(0, 0, 0, 0.12);
  --shadow-a-3-right: 10px 0px 30px rgba(0, 0, 0, 0.12);
  --shadow-b-1-down: 0px 3px 7px rgba(0, 0, 0, 0.30);
  --shadow-b-1-top: 0px -3px 7px rgba(0, 0, 0, 0.30);
  --shadow-b-1-left: -3px 0px 7px rgba(0, 0, 0, 0.30);
  --shadow-b-1-right: 3px 0px 7px rgba(0, 0, 0, 0.30);
  --shadow-b-2-down: 0px 6px 14px rgba(0, 0, 0, 0.30);
  --shadow-b-3-down: 0px 10px 30px rgba(0, 0, 0, 0.30);
  --shadow-b-2-top: 0px -6px 14px rgba(0, 0, 0, 0.30);
  --shadow-b-2-left: -6px 0px 14px rgba(0, 0, 0, 0.30);
  --shadow-b-2-right: 6px 0px 14px rgba(0, 0, 0, 0.30);
  --shadow-b-3-top: 0px -10px 30px rgba(0, 0, 0, 0.30);
  --shadow-b-3-left: -10px 0px 30px rgba(0, 0, 0, 0.30);
  --shadow-b-3-right: 10px 0px 30px rgba(0, 0, 0, 0.30);
}

@media screen and (prefers-color-scheme: dark) {

:root {

  --color-santa-card-f: var(--color-santa-card-f-dark);
  --color-santa-card-e: var(--color-santa-card-e-dark);
  --color-santa-card-d: var(--color-santa-card-d-dark);
  --color-santa-card-c: var(--color-santa-card-c-dark);
  --color-santa-card-b: var(--color-santa-card-b-dark);
  --color-santa-card-a: var(--color-santa-card-a-dark);
  --color-riiid-secondary-ya: var(--color-riiid-secondary-ya-dark);
  --color-riiid-secondary-yb: var(--color-riiid-secondary-yb-dark);
  --color-riiid-secondary-yc: var(--color-riiid-secondary-yc-dark);
  --color-riiid-secondary-ga: var(--color-riiid-secondary-ga-dark);
  --color-riiid-secondary-gb: var(--color-riiid-secondary-gb-dark);
  --color-riiid-secondary-gc: var(--color-riiid-secondary-gc-dark);
  --color-riiid-secondary-pa: var(--color-riiid-secondary-pa-dark);
  --color-riiid-secondary-pb: var(--color-riiid-secondary-pb-dark);
  --color-riiid-secondary-pc: var(--color-riiid-secondary-pc-dark);
  --color-riiid-secondary-ra: var(--color-riiid-secondary-ra-dark);
  --color-riiid-secondary-rb: var(--color-riiid-secondary-rb-dark);
  --color-riiid-secondary-rc: var(--color-riiid-secondary-rc-dark);
  --color-riiid-secondary-va: var(--color-riiid-secondary-va-dark);
  --color-riiid-secondary-vb: var(--color-riiid-secondary-vb-dark);
  --color-riiid-secondary-vc: var(--color-riiid-secondary-vc-dark);
  --color-santa-a: var(--color-santa-a-dark);
  --color-santa-b: var(--color-santa-b-dark);
  --color-santa-c: var(--color-santa-c-dark);
  --color-santa-d: var(--color-santa-d-dark);
  --color-santa-e: var(--color-santa-e-dark);
  --color-santa-f: var(--color-santa-f-dark);
  --color-santa-g: var(--color-santa-g-dark);
  --color-santa-h: var(--color-santa-h-dark);
  --color-santa-i: var(--color-santa-i-dark);
  --color-santa-j: var(--color-santa-j-dark);
  --color-santa-k: var(--color-santa-k-dark);
  --color-santa-l: var(--color-santa-l-dark);
  --color-santa-m: var(--color-santa-m-dark);
  --color-santa-n: var(--color-santa-n-dark);
  --color-santa-o: var(--color-santa-o-dark);
  --color-santa-ba: var(--color-santa-ba-dark);
  --color-santa-bb: var(--color-santa-bb-dark);
  --color-santa-bc: var(--color-santa-bc-dark);
  --color-santa-bd: var(--color-santa-bd-dark);
  --color-santa-be: var(--color-santa-be-dark);
  --color-santa-da: var(--color-santa-da-dark);
  --color-santa-db: var(--color-santa-db-dark);
  --color-santa-dc: var(--color-santa-dc-dark);
  --color-santa-dd: var(--color-santa-dd-dark);
  --color-santa-de: var(--color-santa-de-dark);
  --color-riiid-doba: var(--color-riiid-doba-dark);
  --color-riiid-dobb: var(--color-riiid-dobb-dark);
  --color-riiid-dobc: var(--color-riiid-dobc-dark);
  --color-riiid-dobd: var(--color-riiid-dobd-dark);
  --color-riiid-dobe: var(--color-riiid-dobe-dark);
  --color-riiid-dobf: var(--color-riiid-dobf-dark);
  --color-riiid-dobg: var(--color-riiid-dobg-dark);
  --color-riiid-dobh: var(--color-riiid-dobh-dark);
  --color-riiid-dobi: var(--color-riiid-dobi-dark);
  --color-riiid-dobj: var(--color-riiid-dobj-dark);
  --color-riiid-doak: var(--color-riiid-doak-dark);
  --color-riiid-dobl: var(--color-riiid-dobl-dark);
  --color-riiid-dobm: var(--color-riiid-dobm-dark);
  --color-riiid-dobn: var(--color-riiid-dobn-dark);
  --color-riiid-dobo: var(--color-riiid-dobo-dark);
  --color-riiid-spba: var(--color-riiid-spba-dark);
  --color-riiid-spbb: var(--color-riiid-spbb-dark);
  --color-riiid-spbc: var(--color-riiid-spbc-dark);
  --color-riiid-spbd: var(--color-riiid-spbd-dark);
  --color-riiid-spbe: var(--color-riiid-spbe-dark);
  --color-riiid-spbf: var(--color-riiid-spbf-dark);
  --color-riiid-spbg: var(--color-riiid-spbg-dark);
  --color-riiid-spbh: var(--color-riiid-spbh-dark);
  --color-riiid-spbi: var(--color-riiid-spbi-dark);
  --color-riiid-spbj: var(--color-riiid-spbj-dark);
  --color-riiid-spbk: var(--color-riiid-spbk-dark);
  --color-riiid-spbl: var(--color-riiid-spbl-dark);
  --color-riiid-spbm: var(--color-riiid-spbm-dark);
  --color-riiid-spbn: var(--color-riiid-spbn-dark);
  --color-riiid-spbo: var(--color-riiid-spbo-dark);
  --color-riiid-hzba: var(--color-riiid-hzba-dark);
  --color-riiid-hzbb: var(--color-riiid-hzbb-dark);
  --color-riiid-hzbc: var(--color-riiid-hzbc-dark);
  --color-riiid-hzbd: var(--color-riiid-hzbd-dark);
  --color-riiid-hzbe: var(--color-riiid-hzbe-dark);
  --color-riiid-hzbf: var(--color-riiid-hzbf-dark);
  --color-riiid-hzbg: var(--color-riiid-hzbg-dark);
  --color-riiid-hzbh: var(--color-riiid-hzbh-dark);
  --color-riiid-hzbi: var(--color-riiid-hzbi-dark);
  --color-riiid-hzbj: var(--color-riiid-hzbj-dark);
  --color-riiid-hzbk: var(--color-riiid-hzbk-dark);
  --color-riiid-hzbl: var(--color-riiid-hzbl-dark);
  --color-riiid-hzbm: var(--color-riiid-hzbm-dark);
  --color-riiid-hzbn: var(--color-riiid-hzbn-dark);
  --color-riiid-hzbo: var(--color-riiid-hzbo-dark);
}
}


:root[data-color-scheme=dark] {
--color-santa-card-f: var(--color-santa-card-f-dark);
--color-santa-card-e: var(--color-santa-card-e-dark);
--color-santa-card-d: var(--color-santa-card-d-dark);
--color-santa-card-c: var(--color-santa-card-c-dark);
--color-santa-card-b: var(--color-santa-card-b-dark);
--color-santa-card-a: var(--color-santa-card-a-dark);
--color-riiid-secondary-ya: var(--color-riiid-secondary-ya-dark);
--color-riiid-secondary-yb: var(--color-riiid-secondary-yb-dark);
--color-riiid-secondary-yc: var(--color-riiid-secondary-yc-dark);
--color-riiid-secondary-ga: var(--color-riiid-secondary-ga-dark);
--color-riiid-secondary-gb: var(--color-riiid-secondary-gb-dark);
--color-riiid-secondary-gc: var(--color-riiid-secondary-gc-dark);
--color-riiid-secondary-pa: var(--color-riiid-secondary-pa-dark);
--color-riiid-secondary-pb: var(--color-riiid-secondary-pb-dark);
--color-riiid-secondary-pc: var(--color-riiid-secondary-pc-dark);
--color-riiid-secondary-ra: var(--color-riiid-secondary-ra-dark);
--color-riiid-secondary-rb: var(--color-riiid-secondary-rb-dark);
--color-riiid-secondary-rc: var(--color-riiid-secondary-rc-dark);
--color-riiid-secondary-va: var(--color-riiid-secondary-va-dark);
--color-riiid-secondary-vb: var(--color-riiid-secondary-vb-dark);
--color-riiid-secondary-vc: var(--color-riiid-secondary-vc-dark);
--color-santa-a: var(--color-santa-a-dark);
--color-santa-b: var(--color-santa-b-dark);
--color-santa-c: var(--color-santa-c-dark);
--color-santa-d: var(--color-santa-d-dark);
--color-santa-e: var(--color-santa-e-dark);
--color-santa-f: var(--color-santa-f-dark);
--color-santa-g: var(--color-santa-g-dark);
--color-santa-h: var(--color-santa-h-dark);
--color-santa-i: var(--color-santa-i-dark);
--color-santa-j: var(--color-santa-j-dark);
--color-santa-k: var(--color-santa-k-dark);
--color-santa-l: var(--color-santa-l-dark);
--color-santa-m: var(--color-santa-m-dark);
--color-santa-n: var(--color-santa-n-dark);
--color-santa-o: var(--color-santa-o-dark);
--color-santa-ba: var(--color-santa-ba-dark);
--color-santa-bb: var(--color-santa-bb-dark);
--color-santa-bc: var(--color-santa-bc-dark);
--color-santa-bd: var(--color-santa-bd-dark);
--color-santa-be: var(--color-santa-be-dark);
--color-santa-da: var(--color-santa-da-dark);
--color-santa-db: var(--color-santa-db-dark);
--color-santa-dc: var(--color-santa-dc-dark);
--color-santa-dd: var(--color-santa-dd-dark);
--color-santa-de: var(--color-santa-de-dark);
--color-riiid-doba: var(--color-riiid-doba-dark);
--color-riiid-dobb: var(--color-riiid-dobb-dark);
--color-riiid-dobc: var(--color-riiid-dobc-dark);
--color-riiid-dobd: var(--color-riiid-dobd-dark);
--color-riiid-dobe: var(--color-riiid-dobe-dark);
--color-riiid-dobf: var(--color-riiid-dobf-dark);
--color-riiid-dobg: var(--color-riiid-dobg-dark);
--color-riiid-dobh: var(--color-riiid-dobh-dark);
--color-riiid-dobi: var(--color-riiid-dobi-dark);
--color-riiid-dobj: var(--color-riiid-dobj-dark);
--color-riiid-doak: var(--color-riiid-doak-dark);
--color-riiid-dobl: var(--color-riiid-dobl-dark);
--color-riiid-dobm: var(--color-riiid-dobm-dark);
--color-riiid-dobn: var(--color-riiid-dobn-dark);
--color-riiid-dobo: var(--color-riiid-dobo-dark);
--color-riiid-spba: var(--color-riiid-spba-dark);
--color-riiid-spbb: var(--color-riiid-spbb-dark);
--color-riiid-spbc: var(--color-riiid-spbc-dark);
--color-riiid-spbd: var(--color-riiid-spbd-dark);
--color-riiid-spbe: var(--color-riiid-spbe-dark);
--color-riiid-spbf: var(--color-riiid-spbf-dark);
--color-riiid-spbg: var(--color-riiid-spbg-dark);
--color-riiid-spbh: var(--color-riiid-spbh-dark);
--color-riiid-spbi: var(--color-riiid-spbi-dark);
--color-riiid-spbj: var(--color-riiid-spbj-dark);
--color-riiid-spbk: var(--color-riiid-spbk-dark);
--color-riiid-spbl: var(--color-riiid-spbl-dark);
--color-riiid-spbm: var(--color-riiid-spbm-dark);
--color-riiid-spbn: var(--color-riiid-spbn-dark);
--color-riiid-spbo: var(--color-riiid-spbo-dark);
--color-riiid-hzba: var(--color-riiid-hzba-dark);
--color-riiid-hzbb: var(--color-riiid-hzbb-dark);
--color-riiid-hzbc: var(--color-riiid-hzbc-dark);
--color-riiid-hzbd: var(--color-riiid-hzbd-dark);
--color-riiid-hzbe: var(--color-riiid-hzbe-dark);
--color-riiid-hzbf: var(--color-riiid-hzbf-dark);
--color-riiid-hzbg: var(--color-riiid-hzbg-dark);
--color-riiid-hzbh: var(--color-riiid-hzbh-dark);
--color-riiid-hzbi: var(--color-riiid-hzbi-dark);
--color-riiid-hzbj: var(--color-riiid-hzbj-dark);
--color-riiid-hzbk: var(--color-riiid-hzbk-dark);
--color-riiid-hzbl: var(--color-riiid-hzbl-dark);
--color-riiid-hzbm: var(--color-riiid-hzbm-dark);
--color-riiid-hzbn: var(--color-riiid-hzbn-dark);
--color-riiid-hzbo: var(--color-riiid-hzbo-dark);

}


:root[data-color-scheme=light] {
--color-santa-card-f: var(--color-santa-card-f-light);
--color-santa-card-e: var(--color-santa-card-e-light);
--color-santa-card-d: var(--color-santa-card-d-light);
--color-santa-card-c: var(--color-santa-card-c-light);
--color-santa-card-b: var(--color-santa-card-b-light);
--color-santa-card-a: var(--color-santa-card-a-light);
--color-riiid-secondary-ya: var(--color-riiid-secondary-ya-light);
--color-riiid-secondary-yb: var(--color-riiid-secondary-yb-light);
--color-riiid-secondary-yc: var(--color-riiid-secondary-yc-light);
--color-riiid-secondary-ga: var(--color-riiid-secondary-ga-light);
--color-riiid-secondary-gb: var(--color-riiid-secondary-gb-light);
--color-riiid-secondary-gc: var(--color-riiid-secondary-gc-light);
--color-riiid-secondary-pa: var(--color-riiid-secondary-pa-light);
--color-riiid-secondary-pb: var(--color-riiid-secondary-pb-light);
--color-riiid-secondary-pc: var(--color-riiid-secondary-pc-light);
--color-riiid-secondary-ra: var(--color-riiid-secondary-ra-light);
--color-riiid-secondary-rb: var(--color-riiid-secondary-rb-light);
--color-riiid-secondary-rc: var(--color-riiid-secondary-rc-light);
--color-riiid-secondary-va: var(--color-riiid-secondary-va-light);
--color-riiid-secondary-vb: var(--color-riiid-secondary-vb-light);
--color-riiid-secondary-vc: var(--color-riiid-secondary-vc-light);
--color-santa-a: var(--color-santa-a-light);
--color-santa-b: var(--color-santa-b-light);
--color-santa-c: var(--color-santa-c-light);
--color-santa-d: var(--color-santa-d-light);
--color-santa-e: var(--color-santa-e-light);
--color-santa-f: var(--color-santa-f-light);
--color-santa-g: var(--color-santa-g-light);
--color-santa-h: var(--color-santa-h-light);
--color-santa-i: var(--color-santa-i-light);
--color-santa-j: var(--color-santa-j-light);
--color-santa-k: var(--color-santa-k-light);
--color-santa-l: var(--color-santa-l-light);
--color-santa-m: var(--color-santa-m-light);
--color-santa-n: var(--color-santa-n-light);
--color-santa-o: var(--color-santa-o-light);
--color-santa-ba: var(--color-santa-ba-light);
--color-santa-bb: var(--color-santa-bb-light);
--color-santa-bc: var(--color-santa-bc-light);
--color-santa-bd: var(--color-santa-bd-light);
--color-santa-be: var(--color-santa-be-light);
--color-santa-da: var(--color-santa-da-light);
--color-santa-db: var(--color-santa-db-light);
--color-santa-dc: var(--color-santa-dc-light);
--color-santa-dd: var(--color-santa-dd-light);
--color-santa-de: var(--color-santa-de-light);
--color-riiid-doba: var(--color-riiid-doba-light);
--color-riiid-dobb: var(--color-riiid-dobb-light);
--color-riiid-dobc: var(--color-riiid-dobc-light);
--color-riiid-dobd: var(--color-riiid-dobd-light);
--color-riiid-dobe: var(--color-riiid-dobe-light);
--color-riiid-dobf: var(--color-riiid-dobf-light);
--color-riiid-dobg: var(--color-riiid-dobg-light);
--color-riiid-dobh: var(--color-riiid-dobh-light);
--color-riiid-dobi: var(--color-riiid-dobi-light);
--color-riiid-dobj: var(--color-riiid-dobj-light);
--color-riiid-dobk: var(--color-riiid-dobk-light);
--color-riiid-dobl: var(--color-riiid-dobl-light);
--color-riiid-dobm: var(--color-riiid-dobm-light);
--color-riiid-dobn: var(--color-riiid-dobn-light);
--color-riiid-dobo: var(--color-riiid-dobo-light);
--color-riiid-spba: var(--color-riiid-spba-light);
--color-riiid-spbb: var(--color-riiid-spbb-light);
--color-riiid-spbc: var(--color-riiid-spbc-light);
--color-riiid-spbd: var(--color-riiid-spbd-light);
--color-riiid-spbe: var(--color-riiid-spbe-light);
--color-riiid-spbf: var(--color-riiid-spbf-light);
--color-riiid-spbg: var(--color-riiid-spbg-light);
--color-riiid-spbh: var(--color-riiid-spbh-light);
--color-riiid-spbi: var(--color-riiid-spbi-light);
--color-riiid-spbj: var(--color-riiid-spbj-light);
--color-riiid-spbk: var(--color-riiid-spbk-light);
--color-riiid-spbl: var(--color-riiid-spbl-light);
--color-riiid-spbm: var(--color-riiid-spbm-light);
--color-riiid-spbn: var(--color-riiid-spbn-light);
--color-riiid-spbo: var(--color-riiid-spbo-light);
--color-riiid-hzba: var(--color-riiid-hzba-light);
--color-riiid-hzbb: var(--color-riiid-hzbb-light);
--color-riiid-hzbc: var(--color-riiid-hzbc-light);
--color-riiid-hzbd: var(--color-riiid-hzbd-light);
--color-riiid-hzbe: var(--color-riiid-hzbe-light);
--color-riiid-hzbf: var(--color-riiid-hzbf-light);
--color-riiid-hzbg: var(--color-riiid-hzbg-light);
--color-riiid-hzbh: var(--color-riiid-hzbh-light);
--color-riiid-hzbi: var(--color-riiid-hzbi-light);
--color-riiid-hzbj: var(--color-riiid-hzbj-light);
--color-riiid-hzbk: var(--color-riiid-hzbk-light);
--color-riiid-hzbl: var(--color-riiid-hzbl-light);
--color-riiid-hzbm: var(--color-riiid-hzbm-light);
--color-riiid-hzbn: var(--color-riiid-hzbn-light);
--color-riiid-hzbo: var(--color-riiid-hzbo-light);

}

:root {
  --max-width-component-top-banner: 1080px;

  --max-width-component-top-nav-bar: 1080px;

  color: rgb(var(--color-santa-o));

  background-color: rgb(var(--color-santa-a));

  color-scheme: light;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
  }
}

:root[data-color-scheme="light"] {
  color-scheme: light;
}

:root[data-color-scheme="dark"] {
  color-scheme: dark;
}

